import React, { useRef, useState } from 'react';
import './Homefooter.css'
import { Link } from 'react-router-dom';
import logo from "../Images/AVH_Logo_Rework_1.webp";
import hospital from "../Images/Asian Vascular Exterior_2.webp"
import '../Components/Nav.css'
import axios from 'axios';
import '../Components/Nav.css'

const Homefooter = () => {

    const donevis = useRef()

    var date_zone = new Date()


    const [data,setdata] = useState({
        First_Name:'',
        Family_Name:'',
        Phone_Number:'',
        Email:'',
        Subject:'',
        Questions:'',
        Register_Date:date_zone.toLocaleDateString(),
        Register_Time:date_zone.toLocaleTimeString()
    })

    const handledata = (e) =>{
        setdata({...data,[e.target.name]:e.target.value})
    }
    const handlesubmit = async(e) =>{
        e.preventDefault()

        await axios.post('https://avh-database-default-rtdb.firebaseio.com/form_data.json',data)
        setdata({
            First_Name:'',
            Family_Name:'',
            Phone_Number:'',
            Email:'',
            Subject:'',
            Questions:''
        })
        donevis.current.style.display = 'block'

    }

    const donehide =  () =>{
        donevis.current.style.display = 'none'
        window.location.reload()
      
      }


    const face_book = ()=>{
        window.open('https://www.facebook.com/asianvascularhospitals', '_blank', 'noopener,noreferrer');
    }
    const insta = () =>{
        window.open('https://www.instagram.com/asianvascularhospitals/', '_blank', 'noopener,noreferrer');
    }
    const youtube = () =>{
        window.open('https://youtube.com/@asianvascularhospital?feature=shared', '_blank', 'noopener,noreferrer');

    }

    const formdis = useRef()

    const formopen = () =>{
        formdis.current.classList.add('formvis')
      }
      const forminvis = () =>{
        formdis.current.classList.remove('formvis')
      }

    return (
        <div>
            <section id='h_footer' data-aos="fade-up">
                <div className="h_footer  gap-5">
                    <div className="h_footer_img" data-aos="fade-right">
                        <img className='img_1' src={hospital} alt="" style={{position:"relative",top:"50px",width:"300px"}} />
                    
                    </div>
                    <div className="h_rate_main" data-aos="fade-up">
                        <div className="h_rate ">
                            <Link to='/'><img src={logo} alt="" width="160px" height="43px"/></Link>
                            <div className="h_rating_main ">
                                <div className="">
                                <i class="fa-solid fa-star" style={{color: "#FF8D8D"}}></i>
                                <i class="fa-solid fa-star" style={{color: "#FF8D8D"}}></i>
                                <i class="fa-solid fa-star" style={{color: "#FF8D8D"}}></i>
                                <i class="fa-solid fa-star" style={{color: "#FF8D8D"}}></i>
                                <i class="fa-regular fa-star-half-stroke" style={{color: "#FF8D8D"}}></i>
                                </div>
                                <div className="h_rating">
                                    <small>4.7 Overall Rating</small>
                                    <small>3245 reviews<i class="fa-solid fa-angle-right"></i></small>
                                </div>
                            </div>
                            <span href="/#" className='btn p-2 h_btn' style={{background:"#132573",color:"#fff"}} onClick={formopen}>Book Appointment</span>
                        </div>
                        <div className="h_address d-flex justify-content-between">
                            <div className="">
                                <p>Asian Vascular Hospital
8-2-676 2/B, Road No. 12,
Sri Ram Nagar Colony, Banjara Hills,</p>
                                <p>Hyderabad, Telangana – 500034</p>
                                <p className='tel'><Link to={'tel: +91 733 732 1806​'}>+91 733 732 1814​</Link></p>
                                <p className='tel'><Link to={'mailto:info@asianvascular.in'}>info@asianvascular.in</Link></p>
                            </div>
                            <div className="">
                                <p>Opening hours:</p>
                                <p>Every day: 07:00 – 23:00h</p>
                            </div>
                            <div className="h_address_brands">
                                <span onClick={face_book}><i className='fa-brands fa-facebook'></i></span>
                                <span onClick={insta}><i className='fa-brands fa-instagram'></i></span>
                                <span onClick={youtube}><i className='fa-brands fa-youtube'></i></span>
                            </div>
                        </div>
                        <div className="h_links d-flex justify-content-between">
                            <p><Link to={'/About'}>About</Link></p>
                            <p><Link to={'/Ourdoctors'}>Our Doctors</Link></p>
                            <p><Link to={'#'}>Vascular</Link></p>
                            <p><Link to={'#'}>Cardiology</Link></p>
                        </div>
                        <div className="h_terms_main">
                            <div className="h_terms d-flex">
                                <small><Link to={''}>Terms and Conditions</Link></small>
                                <small><Link to={'/Privacy'}>Privacy Policy</Link></small>
                            </div>
                            <div className="">
                                <small>© 2024 All rights reserved.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="nv_cntct_main" ref={formdis}>
           <div className="cnt_main  nv_cnct" data-aos="zoom-in" >
                            <div className="cnt_sub">
                                <div className="cnt_hd d-grid justify-content-center">
                                  <div className="text-end mb-1" onClick={forminvis}>
                                    <i className="fa-solid fa-x form_wrng" style={{marginTop:'-20px'}}></i>
                                  </div>

                                    <h1 style={{textAlign:'center'}}>Book Your Appointment</h1>

                                    <form className="cnt_cnt_form " onSubmit={handlesubmit}>
                                    <input type="text" placeholder='First Name' onChange={handledata} name='First_Name' value={data.First_Name} required/>
                                        <input type="text" placeholder='Family Name' onChange={handledata} name='Family_Name' value={data.Family_Name} required/>
                                        <input type="tel" placeholder='Phone Number' onChange={handledata} name='Phone_Number' value={data.Phone_Number} maxLength={10} pattern="^\d{10}$" required/>
                                        <input type="email" placeholder='Email' onChange={handledata} name='Email' value={data.Email} required/>
                                        <input type="text" placeholder='Subject' onChange={handledata} name='Subject' value={data.Subject} required/>
                                        <textarea name="Questions" id="" placeholder='Questions' onChange={handledata}  value={data.Questions} required></textarea>
                                        <button className='btn send_btn send_btn_2' type='submit'><i className='fa-solid fa-envelope' ></i> Book Appointment</button>
                                    </form>
                                </div>
                            </div>
              </div>
              </div>

              <div className="done_main" ref={donevis}>
              <div className="done">
              <i class="fa-solid fa-check"></i>
                  <span>Booked Successfully</span>
                <button className="btn send_btn" onClick={donehide}>Done</button>
              </div>
              </div>
        </div>
    );
};

export default Homefooter;