/** @format */

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./Dashboard.css";
import * as XLSX from "xlsx";
// import { List,AutoSizer,CellMeasurerCache,CellMeasurer } from 'react-virtualized';

const Dashboard = () => {
  const [form_data, setform_data] = useState([]);
  const [chatbot_data, setchatbot_data] = useState([]);
  const [data_replace, setdata_replace] = useState(true);
  const [noData, setNo_data] = useState();
  const [noformData, setNo_formdata] = useState();
  const [filteredform_data, setfilteredform_data] = useState([]);
  const [filteredchatbot_data, setfilteredchatbot_data] = useState([]);

  const get_data = async () => {
    try {
      const response = await axios.get(
        "https://avh-database-default-rtdb.firebaseio.com/form_data.json"
      );
      if (response.data) {
        const fetchedData = Object.keys(response.data).map((key) => ({
          id: key,
          ...response.data[key],
        }));
        const sortedData = fetchedData
        .filter(
            (item) =>
              item.Register_Date &&
              item.Register_Time &&
              !isNaN(new Date(item.Register_Date + " " + item.Register_Time))
          )
          .sort((a, b) => {
            const dateTimeA = new Date(a.Register_Date + " " + a.Register_Time).getTime();
            const dateTimeB = new Date(b.Register_Date + " " + b.Register_Time).getTime();
            return dateTimeB - dateTimeA;
          });
        const itemsWithoutDate = fetchedData.filter(
          (item) => !item.Register_Date
        );
        const combinedData = [...sortedData, ...itemsWithoutDate];
        setform_data(combinedData);
        setfilteredform_data(combinedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getbot_data = async () => {
    try {
      const response = await axios.get(
        "https://avh-database-default-rtdb.firebaseio.com/chatbot_data.json"
      );
      if (response.data) {
        // Convert Firebase object to an array
        const fetchedData = Object.keys(response.data).map((key) => ({
          id: key,
          ...response.data[key],
        }));
        const sortedItems = fetchedData
        .filter(
          (item) =>
            item.Register_date &&
            item.Register_time &&
            !isNaN(new Date(item.Register_date + " " + item.Register_time))
        )
        .sort((a, b) => {
          const dateTimeA = new Date(a.Register_date + " " + a.Register_time).getTime();
          const dateTimeB = new Date(b.Register_date + " " + b.Register_time).getTime();
          return dateTimeB - dateTimeA; 
        });
        setchatbot_data(sortedItems);
        setfilteredchatbot_data(sortedItems);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    get_data();
    getbot_data();
  }, []);

  const data_change = (e) => {
    if (e.target.value === "Form") {
      setdata_replace(false);
    } else if (e.target.value === "Chat") {
      setdata_replace(true);
    }
  };
  const formattedDate = (date) => {
    const fullDate = new Date(date);
    const year = fullDate
      .getFullYear()
      .toString()
      .slice(-2);
    const formatted = fullDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    return `${formatted} ${year}`;
  };

  const getWeekNumber = (date) => {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
    return Math.ceil((days + 1) / 7);
  };

  const handle_sort = (e) => {
    if (e.target.value === "day_asc") {
        const sortedItems = filteredform_data
          .filter(
            (item) =>
              item.Register_Date &&
              item.Register_Time &&
              !isNaN(new Date(item.Register_Date + " " + item.Register_Time))
          )
          .sort((a, b) => {
            const dateTimeA = new Date(a.Register_Date + " " + a.Register_Time).getTime();
            const dateTimeB = new Date(b.Register_Date + " " + b.Register_Time).getTime();
            return dateTimeA - dateTimeB;
          });
        setfilteredform_data([...sortedItems]);
      } else if (e.target.value === "day_desc") {
        const sortedItems = filteredform_data
          .filter(
            (item) =>
              item.Register_Date &&
              item.Register_Time &&
              !isNaN(new Date(item.Register_Date + " " + item.Register_Time))
          )
          .sort((a, b) => {
            const dateTimeA = new Date(a.Register_Date + " " + a.Register_Time).getTime();
            const dateTimeB = new Date(b.Register_Date + " " + b.Register_Time).getTime();
            return dateTimeB - dateTimeA; 
          });
        setfilteredform_data([...sortedItems]);
      }
    };
    console.log(filteredform_data)
  const handle_sort_two = (e) => {
    if (e.target.value === "day_asc") {
      const sortedItems = filteredchatbot_data
        .filter(
          (item) =>
            item.Register_date &&
            item.Register_time &&
            !isNaN(new Date(item.Register_date + " " + item.Register_time))
        )
        .sort((a, b) => {
          const dateTimeA = new Date(a.Register_date + " " + a.Register_time).getTime();
          const dateTimeB = new Date(b.Register_date + " " + b.Register_time).getTime();
          return dateTimeA - dateTimeB;
        });
      setfilteredchatbot_data([...sortedItems]);
    } else if (e.target.value === "day_desc") {
      const sortedItems = filteredchatbot_data
        .filter(
          (item) =>
            item.Register_date &&
            item.Register_time &&
            !isNaN(new Date(item.Register_date + " " + item.Register_time))
        )
        .sort((a, b) => {
          const dateTimeA = new Date(a.Register_date + " " + a.Register_time).getTime();
          const dateTimeB = new Date(b.Register_date + " " + b.Register_time).getTime();
          return dateTimeB - dateTimeA; 
        });
      setfilteredchatbot_data([...sortedItems]);
    }
  };
  // const search_val = useRef()

  // const inp_val = (e)=>{
  //     if(e.target.value !==''){
  //         const search_fil = form_data.filter(k=>k.First_Name.includes(search) )
  //         setform_data(search_fil)
  //     }
  //     else{
  //         get_data()
  //     }
  // }

  const handleMonthFilter = (e) => {
    const selectedMonth = e.target.value;
    if (data_replace && selectedMonth) {
      setfilteredchatbot_data(chatbot_data);
      const filteredData = chatbot_data.filter((item) => {
        const itemMonth = new Date(item.Register_date).getMonth() + 1;
        return itemMonth === parseInt(selectedMonth, 10);
      });
      if (filteredData.length > 0) {
        setfilteredchatbot_data(filteredData);
        setNo_data();
      } else {
        setfilteredchatbot_data(chatbot_data);
        setNo_data(`No data found for the ${selectedMonth} month.`);
      }
    } else if (!data_replace && selectedMonth) {
      setfilteredform_data(form_data);
      const filteredData = form_data.filter((item) => {
        const itemMonth = new Date(item.Register_Date).getMonth() + 1;
        return itemMonth === parseInt(selectedMonth, 10);
      });
      if (filteredData.length > 0) {
        setfilteredform_data(filteredData);
        setNo_formdata();
      } else {
        setfilteredform_data(form_data);
        setNo_formdata(`No data found for the ${selectedMonth} month.`);
      }
    }
  };
 
  const downloadExcel = () => {
    if (!data_replace) {
      const filtereddata = filteredform_data.map(({ id, ...rest }) => rest);

      const workbook = XLSX.utils.book_new();

      // Convert JSON data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filtereddata);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate an Excel file and download it
      XLSX.writeFile(workbook, "Form_data.xlsx");
    } else {
      const filtereddata = filteredchatbot_data.map(({ id, ...rest }) => rest);

      const workbook = XLSX.utils.book_new();

      // Convert JSON data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filtereddata);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate an Excel file and download it
      XLSX.writeFile(workbook, "ChatBot_data.xlsx");
    }

    // Create a new workbook
  };
  return (
    <div>
      <div className="dash">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="mb-3">Dashboard</h1>
          <div className="d-flex align-items-center gap-2">
            <select name="" id="" onChange={data_change}>
              <option value="Chat">Chat Bot</option>
              <option value="Form">Form Data</option>
            </select>
            {!data_replace && (
              <select name="" id="" onChange={handle_sort}>
                <option value="day_desc">Sort By Latest Date</option>
                <option value="day_asc">Sort By Oldest Date</option>
                {/* <option value="month_asc">Sort By oldest Month </option>
                <option value="month_desc">Sort By latest Month</option>
                <option value="week_asc">Sort By oldest Week</option>
                <option value="week_desc">Sort By latest Week</option> */}
              </select>
            )}

            {data_replace && (
              <select name="" id="" onChange={handle_sort_two}>
                <option value="day_desc">Sort By Latest Date</option>
                <option value="day_asc">Sort By Oldest Date</option>
                {/* <option value="month_asc">Sort By oldest Month </option>
                <option value="month_desc">Sort By latest Month</option>
                <option value="week_asc">Sort By oldest Week</option>
                <option value="week_desc">Sort By latest Week</option> */}
              </select>
            )}
            <select onChange={handleMonthFilter} id="month-select">
              <option value="" disabled selected hidden>
                Select a Month
              </option>
              {[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ].map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
            <div className="btn dwld" onClick={downloadExcel}>
              Download
            </div>
            {/* <input type="search" className='data_search' placeholder='Search Name' ref={search_val} onChange={inp_val}/> */}
          </div>
        </div>
        <table>
          {!data_replace && (
            <tr>
              {/* Form data */}

              <th>First Name</th>
              <th>Family Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Questions</th>
              <th>Subject</th>
              <th>Register Date</th>
              <th>Register Time</th>
            </tr>
          )}

          {data_replace && (
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>Disease</th>
              <th>Habits & Conditions</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Location</th>
              <th>Book Appointment</th>
              <th>Register Date</th>
              <th>Register Time</th>
            </tr>
          )}

          {!data_replace &&
          filteredform_data?.length > 0 &&
          noformData == undefined
            ? filteredform_data.map((e) => {
                return (
                  <tr key={e.id}>
                    {/* Form data */}
                    <td>{e.First_Name}</td>
                    <td>{e.Family_Name}</td>
                    <td>{e.Email}</td>
                    <td>{e.Phone_Number}</td>
                    <td>{e.Questions}</td>
                    <td>{e.Subject}</td>
                    <td>{formattedDate(e.Register_Date)}</td>
                    <td>{e.Register_Time}</td>
                  </tr>
                );
              })
            : !data_replace && (
                <tr>
                  <td colSpan="10" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}

          {data_replace &&
          filteredchatbot_data?.length > 0 &&
          noData == undefined
            ? filteredchatbot_data.map((e) => {
                return (
                  <tr key={e.id}>
                    {/* Chat Bot Data */}
                    <td>{e.name}</td>
                    <td>{e.age}</td>
                    <td>{e.Disease}</td>
                    <td>{e.Habits_Conditions}</td>
                    <td>{e.Phone_Number}</td>
                    <td>{e.email}</td>
                    <td>{e.Location}</td>
                    <td>{e.Appointment}</td>
                    <td>{formattedDate(e.Register_date)}</td>
                    <td>{e.Register_time}</td>
                  </tr>
                );
              })
            : data_replace && (
                <tr>
                  <td colSpan="10" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
