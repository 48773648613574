
import React, { useEffect, useRef, useState } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import "./Chatbot.css";
import bot_avatar from "../Images/robot-assistant.png";
import robot from "../Images/AVH_ChatBot_LogoAnimation_1.gif";
import bot_user from "../Images/user (1).png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import MultiSelect from "./MultiSelect";

const Chat = () => {
  const locationserv = useLocation();

  const services = locationserv.pathname === "/Dashboard";

  const [isOpen, setIsOpen] = useState(false);

  const [close, setclose] = useState(false);

  const theme = {
    background: "#f5f8fb", // Background color of the chatbot
    fontFamily: "'Times New Roman', Times, serif",
    headerBgColor: "#132573", // Header background color
    headerFontColor: "#fff", // Header text color
    headerFontSize: "15px",
    botBubbleColor: "#132573", // Background color of bot messages
    botFontColor: "#fff", // Text color of bot messages
    userBubbleColor: "#fff", // Background color of user messages
    userFontColor: "#4a4a4a", // Text color of user messages
  };

  const chatbot1 = useRef();
  const chatbot2 = useRef();

  //  const close_chat = () =>{
  //   setclose(false)
  //  }

  useEffect(() => {
    setTimeout(() => {
      if (!services) {
        chatbot2.current.style.display = "block";
      }
    }, 30000);
  }, []);

  const close_chat = () => {
    chatbot2.current.style.display = "none";
    setclose(true);
    setIsOpen(true);
  };

  const steps = [
    {
      id: "Welcome",
      message: "Please Select a Language",
      trigger: "lan",
    },
    {
      id: "lan",
      options: [
        {
          value: "English",
          label: "English",
          trigger: "Great",
        },
        {
          value: "Hindi",
          label: "हिंदी",
          trigger: "Greathin",
        },
        {
          value: "Telugu",
          label: "తెలుగు",
          trigger: "Greattel",
        },
      ],
    },
    {
      id: "Great",
      message: "Welcome to True Care of Asian Vascular Hospitals",
      trigger: "name",
    },
    {
      id: "Greathin",
      message: "एशियन वैस्कुलर हॉस्पिटल्स की ट्रू केयर में आपका स्वागत है",
      trigger: "namehin",
    },
    {
      id: "Greattel",
      message: "ఆసియన్ వాస్కులర్ హాస్పిటల్స్ యొక్క ట్రూ కేర్‌కు స్వాగతం",
      trigger: "nametel",
    },
    {
      id: "name",
      message: "Please Enter Your Name",
      trigger: "typ_name",
    },
    {
      id: "namehin",
      message: "कृपया अपना नाम दर्ज करें",
      trigger: "typ_name_hin",
    },
    {
      id: "nametel",
      message: "దయచేసి మీ పేరును నమోదు చేయండి",
      trigger: "typ_name_tel",
    },
    {
      id: "typ_name_tel",
      user: true,
      trigger: "age_tel",
    },
    {
      id: "age_tel",
      message: "హాయ్ {previousValue}, దయచేసి మీ వయస్సును నమోదు చేయండి",
      trigger: "typ_age_tel",
    },

    // english

    {
      id: "typ_name",
      user: true,
      validator: (value) => {
        const nameRegex = /^[a-zA-Z\s]+$/; // Allows alphabets and spaces only
        if (!value.trim()) {
          return "Name cannot be empty.";
        }
        if (value.length < 2) {
          return "Name must be at least 2 characters long.";
        }
        if (!nameRegex.test(value)) {
          return "Name can only contain alphabets and spaces.";
        }
        return true;
      },
      trigger: "age",
    },
    {
      id: "age",
      message: "Hi {previousValue}, Please Enter Your Age",
      trigger: "typ_age",
    },
    {
      id: "typ_age",
      user: true,
      validator: (value) => {
        const age = parseInt(value, 10);
        if (isNaN(age) || age < 1 || age > 120) {
          return "Please enter a valid age between 1 and 120.";
        }
        return true;
      },
      trigger: "disease",
    },
    {
      id: "disease",
      message: "Please Select Disease",
      trigger: "dis_choice",
    },
    {
      id: "dis_choice",
      component: (
        <MultiSelect
          options={[
            { value: "Varicose Veins", label: "Varicose Veins / Venous Insufficiency" },
            { value: "PAD", label: "Peripheral Arterial Disease (PAD)" },
            { value: "DVT", label: "Deep Vein Thrombosis (DVT)" },
            { value: "Heart Condition", label: "Heart Condition" },
            { value: "Others", label: "Others" },
          ]}
        />
      ),
      // asMessage: true,
      waitAction: true,
      trigger: "hc_habits",
    },
    {
      id: "hc_habits",
      message: "Please Select Habits & Conditions",
      trigger: "hc_habits_list",
    },
 {
    id: "hc_habits_list",
    component: (
      <MultiSelect
        options={[
          { value: "Smoking", label: "Smoking" },
          { value: "Diabetes", label: "Diabetes" },
          { value: "Hypertension", label: "Hypertension" },
          { value: "Obesity", label: "Obesity" },
          { value: "Family History", label: "Family History of PAD/Varicose Veins" },
          { value: "Injury", label: "History of Injury" },
          { value: "None", label: "None" },
        ]}
      />
    ),
    // asMessage: true,
    waitAction: true,
    trigger: "hc_contact",
  },

    {
      id: "hc_contact",
      message: "Please Enter Your Mobile Number",
      trigger: "type_hc_contact",
    },
    {
      id: "type_hc_contact",
      user: true,
      validator: (value) => {
        const phoneRegex = /^[0-9]{10}$/; 
        if (!phoneRegex.test(value)) {
          return "Please enter a valid 10-digit phone number.";
        }
        return true;
      },
      trigger: "hc_email",
    },
    {
      id: "hc_email",
      message: "Enter Email Address (Optional), Select (Yes) or (No)",
      trigger: "hc_sel_email",
    },
    {
      id: "hc_sel_email",
      options: [
        {
          value: "Yes",
          label: "Yes",
          trigger: "hc_ent_email",
        },
        {
          Value: "NO",
          label: "No",
          trigger: "hc_address",
        },
      ],
    },
    {
      id: "hc_ent_email",
      message: "Please Enter Your Email",
      trigger: "hc_write_email",
    },
    {
      id: "hc_write_email",
      user: true,
      validator: (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          return "Please enter a valid email address.";
        }
        return true;
      },
      trigger: "hc_address",
    },
    {
      id: "hc_address",
      message: "Please Enter Your Location (City/Town/Village), State",
      trigger: "hc_loc",
    },
    {
      id: "hc_loc",
      user: true,
      validator: (value) => {
        const locationRegex = /^[a-zA-Z\s]+$/; // Allows alphabets and spaces only
        if (!value.trim()) {
          return "Location cannot be empty.";
        }
        if (value.length < 3) {
          return "Location must be at least 3 characters long.";
        }
        if (!locationRegex.test(value)) {
          return "Location can only contain alphabets and spaces.";
        }
        return true;
      },
      trigger: "appoint",
    },

    {
      id: "appoint",
      message: "Would you like to make an appointment?",
      trigger: "sel_appoint",
    },
    {
      id: "sel_appoint",
      options: [
        {
          value: "Yes",
          label: "Yes",
          trigger: "end",
        },
        {
          value: "No",
          label: "No",
          trigger: "end",
        },
      ],
    },
    {
      id: "end",
      message: "Thank You For Visiting Asian Vascular Hospitals",
      end: true,
    },

    // telugu

    {
      id: "typ_age_tel",
      user: true,
      validator: (value) => {
        const age = parseInt(value, 10);
        if (isNaN(age) || age < 1 || age > 120) {
          return "దయచేసి 1 మరియు 120 మధ్య చెల్లుబాటు అయ్యే వయస్సును నమోదు చేయండి.";
        }
        return true;
      },
      trigger: "disease_tel",
    },
    {
      id: "disease_tel",
      message: "దయచేసి వ్యాధిని ఎంచుకోండి",
      trigger: "dis_choice_tel",
    },
    {
      id: "dis_choice_tel",
      component: (
        <MultiSelect
          options={[
            { value: "Varicose Veins", label: " అనారోగ్య సిరలు / సిరల లోపం"},
            { value: "PAD", label: "పరిధీయ ధమని వ్యాధి(PAD)" },
            { value: "DVT", label: " డీప్ వెయిన్ థ్రాంబోసిస్ (DVT)"},
            { value: "Heart Condition", label: "గుండె పరిస్థితి"},
            { value: "Others", label: "ఇతర వ్యాధులు" },
          ]}
        />
      ),
      // asMessage: true,
      waitAction: true,
      trigger: "hc_habits_tel",
    },
    {
      id: "hc_habits_tel",
      message: "దయచేసి అలవాట్లు & షరతులను ఎంచుకోండి",
      trigger: "hc_habits_list_tel",
    },
    {
      id: "hc_habits_list_tel",
      component: (
        <MultiSelect
          options={[
            { value: "Smoking", label: "ధూమపానం" },
            { value: "Diabetes", label: "మధుమేహం" },
            { value: "Hypertension", label: "హైపర్ టెన్షన్" },
            { value: "Obesity", label: "ఊబకాయం" },
            {
              value: "Family History",
              label: "PAD యొక్క కుటుంబ చరిత్ర, అనారోగ్య సిరలు",
            },
            { value: "Injury", label: "గాయం యొక్క చరిత్ర" },
            { value: "None", label: "ఏదీ లేదు" },
          ]}
        />
      ),
      // asMessage: true,
      waitAction: true,
      trigger: "hc_contact_tel",
    },
    {
      id: "hc_contact_tel",
      message: "దయచేసి మీ మొబైల్ నంబర్‌ను నమోదు చేయండి",
      trigger: "type_hc_contact_tel",
    },
    {
      id: "type_hc_contact_tel",
      user: true,
      validator: (value) => {
        const phoneRegex = /^[0-9]{10}$/; // Accepts 10 digits
        if (!phoneRegex.test(value)) {
          return "దయచేసి చెల్లుబాటు అయ్యే 10-అంకెల ఫోన్ నంబర్‌ను నమోదు చేయండి.";
        }
        return true;
      },
      trigger: "hc_email_tel",
    },
    {
      id: "hc_email_tel",
      message:
        "ఇమెయిల్ చిరునామాను నమోదు చేయండి (ఐచ్ఛికం), ఎంచుకోండి (అవును) లేదా (లేదు)",
      trigger: "hc_sel_email_tel",
    },
    {
      id: "hc_sel_email_tel",
      options: [
        {
          value: "Yes",
          label: "అవును",
          trigger: "hc_ent_email_tel",
        },
        {
          Value: "NO",
          label: "లేదు",
          trigger: "hc_address_tel",
        },
      ],
    },
    {
      id: "hc_ent_email_tel",
      message: "దయచేసి మీ ఇమెయిల్‌ను నమోదు చేయండి",
      trigger: "hc_write_email_tel",
    },
    {
      id: "hc_write_email_tel",
      user: true,
      validator: (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          return "దయచేసి చెల్లుబాటు అయ్యే ఇమెయిల్ చిరునామాను నమోదు చేయండి.";
        }
        return true;
      },
      trigger: "hc_address_tel",
    },
    {
      id: "hc_address_tel",
      message:
        "దయచేసి మీ స్థానాన్ని (నగరం/పట్టణం/గ్రామం), రాష్ట్రం నమోదు చేయండి",
      trigger: "hc_loc_tel",
    },
    {
      id: "hc_loc_tel",
      user: true,
      validator: (value) => {
        const locationRegex = /^[a-zA-Z\s]+$/; 
        if (!value.trim()) {
          return "స్థానం ఖాళీగా ఉండకూడదు.";
        }
        if (value.length < 3) {
          return "స్థానం తప్పనిసరిగా కనీసం 3 అక్షరాల పొడవు ఉండాలి.";
        }
        if (!locationRegex.test(value)) {
          return "స్థానం అక్షరాలు మరియు ఖాళీలను మాత్రమే కలిగి ఉంటుంది.";
        }
        return true;
      },
      trigger: "appoint_tel",
    },

    {
      id: "appoint_tel",
      message: "మీరు అపాయింట్‌మెంట్ తీసుకోవాలనుకుంటున్నారా?",
      trigger: "sel_appoint_tel",
    },
    {
      id: "sel_appoint_tel",
      options: [
        {
          value: "Yes",
          label: "అవును",
          trigger: "end_tel",
        },
        {
          value: "No",
          label: "లేదు",
          trigger: "end_tel",
        },
      ],
    },
    {
      id: "end_tel",
      message: "ఆసియా వాస్కులర్ హాస్పిటల్స్‌ని సందర్శించినందుకు ధన్యవాదాలు",
      end: true,
    },

    // hindhi
    {
      id: "typ_name_hin",
      user: true,
      validator: (value) => {
        const nameRegex = /^[a-zA-Z\s]+$/; // Allows alphabets and spaces only
        if (!value.trim()) {
          return "नाम रिक्त नहीं रह सकता.";
        }
        if (value.length < 2) {
          return "नाम कम से कम 2 अक्षर लंबा होना चाहिए.";
        }
        if (!nameRegex.test(value)) {
          return "नाम में केवल अक्षर और रिक्त स्थान ही हो सकते हैं।";
        }
        return true;
      },
      trigger: "age_hin",
    },
    {
      id: "age_hin",
      message: "नमस्ते {previousValue}, कृपया अपनी आयु दर्ज करें",
      trigger: "typ_age_hin",
    },
    {
      id: "typ_age_hin",
      user: true,
      validator: (value) => {
        const age = parseInt(value, 10);
        if (isNaN(age) || age < 1 || age > 120) {
          return "कृपया 1 से 120 वर्ष के बीच की वैध आयु दर्ज करें।";
        }
        return true;
      },
      trigger: "disease_hin",
    },
    {
      id: "disease_hin",
      message: "कृपया रोग का चयन करें",
      trigger: "dis_choice_hin",
    },
    {
      id: "dis_choice_hin",
      component: (
        <MultiSelect
          options={[
            { value: "Varicose Veins", label: "वैरिकोज वेंस / शिरापरक अपर्याप्तता"},
            { value: "PAD", label: "परिधीय धमनी रोग (पीएडी)" },
            { value: "DVT", label: " परिधीय धमनी रोग (पीएडी)"},
            { value: "Heart Condition", label: "हृदय की स्थिति"},
            { value: "Others", label: "अन्य" },
          ]}
        />
      ),
      // asMessage: true,
      waitAction: true,
      trigger: "hc_habits_hin",
    },

    {
      id: "hc_habits_hin",
      message: "कृपया आदतें और शर्तें चुनें",
      trigger: "hc_habits_list_hin",
    },
    {
      id: "hc_habits_list_hin",
      component: (
        <MultiSelect
          options={[
            { value: "Smoking", label: "धूम्रपान " },
            { value: "Diabetes", label: "मधुमेह" },
            { value: "Hypertension", label: "उच्च रक्तचाप" },
            { value: "Obesity", label: "मोटापा" },
            {
              value: "Family History",
              label: "पीएडी, वैरिकोज वेन्स का पारिवारिक इतिहास",
            },
            { value: "Injury", label: "चोट का इतिहास" },
            { value: "None", label: "कोई नहीं" },
          ]}
        />
      ),
      // asMessage: true,
      waitAction: true,
      trigger: "hc_contact_hin",
    },

    {
      id: "hc_contact_hin",
      message: "अपना मोबाइल नंबर दर्ज करें",
      trigger: "type_hc_contact_hin",
    },
    {
      id: "type_hc_contact_hin",
      user: true,
      validator: (value) => {
        const phoneRegex = /^[0-9]{10}$/; // Accepts 10 digits
        if (!phoneRegex.test(value)) {
          return "कृपया वैध 10-अंकीय फ़ोन नंबर दर्ज करें.";
        }
        return true;
      },
      trigger: "hc_email_hin",
    },
    {
      id: "hc_email_hin",
      message: "ईमेल पता दर्ज करें (वैकल्पिक), (हां) या (नहीं) चुनें",
      trigger: "hc_sel_email_hin",
    },
    {
      id: "hc_sel_email_hin",
      options: [
        {
          value: "Yes",
          label: "हाँ",
          trigger: "hc_ent_email_hin",
        },
        {
          Value: "NO",
          label: "नहीं",
          trigger: "hc_address_hin",
        },
      ],
    },
    {
      id: "hc_ent_email_hin",
      message: "कृपया अपना ईमेल दर्ज करें",
      trigger: "hc_write_email_hin",
    },
    {
      id: "hc_write_email_hin",
      user: true,
      validator: (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          return "कृपया एक मान्य ईमेल पता प्रविष्ट करें।";
        }
        return true;
      },
      trigger: "hc_address_hin",
    },
    {
      id: "hc_address_hin",
      message: "कृपया अपना स्थान (शहर/कस्बा/गांव), राज्य दर्ज करें",
      trigger: "hc_loc_hin",
    },
    {
      id: "hc_loc_hin",
      user: true,
      validator: (value) => {
        const locationRegex = /^[a-zA-Z\s]+$/; // Allows alphabets and spaces only
        if (!value.trim()) {
          return "स्थान रिक्त नहीं रह सकता.";
        }
        if (value.length < 3) {
          return "स्थान कम से कम 3 अक्षरों का होना चाहिए.";
        }
        if (!locationRegex.test(value)) {
          return "स्थान में केवल अक्षर और रिक्त स्थान ही हो सकते हैं।";
        }
        return true;
      },
      trigger: "appoint_hin",
    },

    {
      id: "appoint_hin",
      message: "क्या आप अपॉइंटमेंट लेना चाहेंगे?",
      trigger: "sel_appoint_hin",
    },
    {
      id: "sel_appoint_hin",
      options: [
        {
          value: "Yes",
          label: "हाँ",
          trigger: "end_hin",
        },
        {
          value: "No",
          label: "नहीं",
          trigger: "end_hin",
        },
      ],
    },
    {
      id: "end_hin",
      message: "एशियाई वैस्कुलर हॉस्पिटल्स में आने के लिए धन्यवाद",
      end: true,
    },
  ];

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  const handleEnd = ({ steps, values }) => {
    try {
      if (values[7] === "No" || values[7] === undefined) {
        const date = new Date();
        const full_date = date.toLocaleDateString();
        const time = date.toLocaleTimeString();
        try {
          // console.log({name:values[1],age:values[2],Symptoms:values[3],Habits_Conditions:values[4],Phone_Number:values[5],Location:values[6],Appointment:values[7],Booked_date:full_date,Booked_time:time})
          axios.post(
            "https://avh-database-default-rtdb.firebaseio.com/chatbot_data.json",
            {
              name: values[1],
              age: values[2],
              Disease: values[3],
              Habits_Conditions: values[4],
              Phone_Number: values[5],
              Location: values[6],
              Appointment: values[7],
              Register_date: full_date,
              Register_time: time,
            }
          );
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          const date = new Date();
          const full_date = date.toLocaleDateString();
          const time = date.toLocaleTimeString();
          axios.post(
            "https://avh-database-default-rtdb.firebaseio.com/chatbot_data.json",
            {
              name: values[1],
              age: values[2],
              Disease: values[3],
              Habits_Conditions: values[4],
              Phone_Number: values[5],
              email: values[7],
              Location: values[8],
              Appointment: values[9],
              Register_date: full_date,
              Register_time: time,
            }
          );
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      className="chatbot-container"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: "999999 ",
      }}
    >
      {close && (
        <div className="">
          {/* {!isOpen && <div className="chatbot-icon" onClick={toggleChatbot} useRef={chatbot1}>
        <img src={robot} alt="" width={'60px'} style={{objectFit:'contain',height:'auto'}}/>
      </div>} */}

          <div className="floating-chatbot ">
            {isOpen && (
              <ThemeProvider theme={theme}>
                <ChatBot
                  steps={steps}
                  floating={true}
                  floatingIcon={robot}
                  floatingStyle={{
                    backgroundColor: "rgba(225,225,225,0.1)",
                    width: "60px",
                    height: "60px", // Change to your desired color
                    borderRadius: "50%", // Keep the button circular
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Optional shadow
                  }}
                  botDelay={1000}
                  handleEnd={handleEnd}
                  // botAvatar={'https://i.gifer.com/XOsX.gif'}
                  botAvatar={bot_avatar}
                  // userAvatar={'https://media2.giphy.com/avatars/HollerStudios/1l8ZK6CbbUM8.gif'}
                  userAvatar={bot_user}
                />
              </ThemeProvider>
            )}
          </div>
        </div>
      )}

      {
        <div className="floating-chatbot2" ref={chatbot2}>
          <div className="floating_wrong" onClick={close_chat}>
            <i className="fa-solid fa-x"></i>
          </div>
          <div className="">
            <ThemeProvider theme={theme}>
              <ChatBot
                steps={steps}
                // floating={true}
                botDelay={1000}
                handleEnd={handleEnd}
                // botAvatar={'https://i.gifer.com/XOsX.gif'}
                botAvatar={bot_avatar}
                // userAvatar={'https://media2.giphy.com/avatars/HollerStudios/1l8ZK6CbbUM8.gif'}
                userAvatar={bot_user}
              />
            </ThemeProvider>
          </div>
        </div>
      }
    </div>
  );
};

export default Chat;
