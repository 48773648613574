/** @format */

import React, { useState } from "react";
import "./Chatbot.css";

const MultiSelect = ({ triggerNextStep, options }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const toggleOption = (value) => {
    setSelectedOptions((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };
  const handleSubmit = () => {
    setIsSubmitted(true);
    triggerNextStep({ value: selectedOptions.join(", ") });
  };

  return (
    <div style={{ display: "inline" }}>
      {isSubmitted ? (
        <span>
          <p style={{ display: "inline", fontSize: "14px" }}>
            {selectedOptions.length > 0
              ? selectedOptions.join(", ")
              : "No options selected"}
          </p>
        </span>
      ) : (
        <>
          <div>
            {options.map((option, index) => (
              <div
                key={index}
                style={{ marginBottom: "5px", display: "flex", gap: "10px" }}
              >
                <input
                  type="checkbox"
                  id={`option-${index}`}
                  value={option.value}
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => toggleOption(option.value)}
                />
                <label
                  htmlFor={`option-${index}`}
                  style={{ marginLeft: "5px" }}
                >
                  {option.label}
                </label>
              </div>
            ))}
          </div>

          <div
            className={"chat_btn"}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              onClick={handleSubmit}
              disabled={selectedOptions.length === 0 || isSubmitted}
            >
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export default MultiSelect;
