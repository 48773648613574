import React, { useEffect, useRef, useState } from 'react';
import './Contact.css'
import Doctors from './Doctors';
import { Link } from 'react-router-dom';
import Mainfooter from './Mainfooter';
import help_desk from '../Images/webp/Asian Vascular Hospitals_HelpDesk_edit.7133eaa935b2b399de4e.webp'
import axios from 'axios';
import '../Components/Nav.css'

const Contact = () => {
    
    // const img = 'https://i0.wp.com/island-iv.com/wp-content/uploads/2020/05/Untitled-20-scaled-1.jpg?resize=1200%2C362&ssl=1'
    const img = help_desk
    const txt = 'Contact'

    const donevis = useRef()

    var date_zone = new Date()



    const [data,setdata] = useState({
        First_Name:'',
        Family_Name:'',
        Phone_Number:'',
        Email:'',
        Subject:'',
        Questions:'',
        Register_Date:date_zone.toLocaleDateString(),
        Register_Time:date_zone.toLocaleTimeString()
    })

    const handledata = (e) =>{
        setdata({...data,[e.target.name]:e.target.value})
    }
    // const done_val = useRef()
    const handlesubmit = async(e) =>{
        e.preventDefault()
        await axios.post('https://avh-database-default-rtdb.firebaseio.com/form_data.json',data)
        setdata({
            First_Name:'',
            Family_Name:'',
            Phone_Number:'',
            Email:'',
            Subject:'',
            Questions:'',
            Register_Date:'',
            Register_Time:''
        })
        donevis.current.style.display = 'block'
       

    }
    

    const donehide =  () =>{
        donevis.current.style.display = 'none'
        window.location.reload()
      }

      

    useEffect(()=>{
        document.title = 'AVH - Contact'
    },[])

    const [load, setload] = useState(false);

    useEffect(() => {
    //   setTimeout(() => {
        setload(true);
    //   }, 1000);
    }, []);

    return (
        <div className="">
        {load?<div>
                <div className="contact_req">
                <Doctors image={img} txt={txt}/>
                </div>


                <section id='Contact'>
                    <div className="cnt_cmnd">
                        <div className="cnt_main" data-aos="fade-right">
                            <div className="cnt_sub">
                                <div className="cnt_hd">
                                    <h1>Address</h1>

                                <div className="cnt_add_txt">
                                    <p>Asian Vascular Hospital 8-2-676 2/B, Road No. 12, Sri Ram Nagar Colony, Banjara Hills, </p>
                                    <p>Hyderabad, Telangana – 500034</p>
                                    <p className='cnt_num'><span>Phone Number:</span><Link> +91 733 732 1814</Link></p>
                                    <p className='cnt_mail'><span>E-Mail:</span><Link> info@asianvascular.in</Link></p>
                                </div>


                                <div className="cnt_add_txt_2">
                                    <p>The hospital has a parking lot for patients and visitors.</p>

                                    <p> The hospital team will appreciate if those whose health condition allow, take the drop off option without parking or use other transport means or alternative parking lots in the area.</p>
                                </div>

                                </div>
                            </div>


                        </div>
                        <div className="cnt_main" data-aos="fade-up">
                            <div className="cnt_sub">
                                <div className="cnt_hd">
                                    <h1>Contact Form</h1>

                                    <form className="cnt_cnt_form" onSubmit={handlesubmit}>
                                        <input type="text" placeholder='First Name' onChange={handledata} name='First_Name' value={data.First_Name} required/>
                                        <input type="text" placeholder='Family Name' onChange={handledata} name='Family_Name' value={data.Family_Name} required/>
                                        <input type="tel" placeholder='Phone Number' onChange={handledata} name='Phone_Number' value={data.Phone_Number} pattern="^\d{10}$" maxLength={10} required/>
                                        <input type="email" placeholder='Email' onChange={handledata} name='Email' value={data.Email} required/>
                                        <input type="text" placeholder='Subject' onChange={handledata} name='Subject' value={data.Subject} required/>
                                        <textarea name="Questions" id="" placeholder='Questions' onChange={handledata}  value={data.Questions} required></textarea>
                                        <button className='btn send_btn' type='submit'><i className='fa-solid fa-envelope' ></i> Send</button>
                                    </form>
                                </div>
                            </div>
                        </div>


                       

                    </div>
                </section>


            <div className="Contact_foot">
                <Mainfooter/>
            </div>

            <div className="done_main" ref={donevis}>
              <div className="done">
              <i class="fa-solid fa-check"></i>
                  <span>Booked Successfully</span>
                <button className="btn send_btn" onClick={donehide} value={'Done'}>Done</button>
              </div>
              </div>

        </div>:<span class="loader"></span>}
        </div>
    );
};

export default React.memo(Contact);
