import React, { useRef, useState } from 'react';
import './Mainfooter.css'
import { FaHandHoldingMedical } from "react-icons/fa";
import { Link } from 'react-router-dom';
import logo from "../Images/AVH_Logo_Rework_1.webp";
import '../Components/Nav.css'
import axios from 'axios';


const Mainfooter = () => {
    const donevis = useRef()

    var date_zone = new Date()



    const [data,setdata] = useState({
        First_Name:'',
        Family_Name:'',
        Phone_Number:'',
        Email:'',
        Subject:'',
        Questions:'',
        Register_Date:date_zone.toLocaleDateString(),
        Register_Time:date_zone.toLocaleTimeString()
    })

    const handledata = (e) =>{
        setdata({...data,[e.target.name]:e.target.value})
    }
    const handlesubmit = async(e) =>{
        e.preventDefault()

        await axios.post('https://avh-database-default-rtdb.firebaseio.com/form_data.json',data)
        setdata({
            First_Name:'',
            Family_Name:'',
            Phone_Number:'',
            Email:'',
            Subject:'',
            Questions:'',
            
        })
        donevis.current.style.display = 'block'

    }
    const donehide =  () =>{
        donevis.current.style.display = 'none'
        window.location.reload()
      }

    const face_book = ()=>{
        window.open('https://www.facebook.com/asianvascularhospitals', '_blank', 'noopener,noreferrer');
    }
    const insta = () =>{
        window.open('https://www.instagram.com/asianvascularhospitals/', '_blank', 'noopener,noreferrer');
    }
    const youtube = () =>{
        window.open('https://youtube.com/@asianvascularhospital?feature=shared', '_blank', 'noopener,noreferrer');

    }
    const linkedin = () =>{
        window.open('https://www.linkedin.com/company/asian-vascular-hospital/', '_blank', 'noopener,noreferrer');
    }

    const formdis = useRef()

    const formopen = () =>{
        formdis.current.classList.add('formvis')
      }
      const forminvis = () =>{
        formdis.current.classList.remove('formvis')
      }

    return (
        <div>
            <section id='Main_footer' data-aos="fade-up">
                <div className="">
                <div className="main_foot">
                    <div className="foot_card">
                        <div className="foot_image">
                           <Link to='/'><img src={logo} alt="" width="170px"/></Link> 
                        </div>
                        <p>We are a family centered practice that incorporates compassion and dedication to promote your well being.</p>
                        <h4><FaHandHoldingMedical className='i'/> <span>+91 733 732 1814</span></h4>
                        <button className='btn btn_clr' onClick={formopen}>Book Appointment <i className='fa-solid fa-angle-right'></i></button>
                    </div>
                    <div className="main_foot_cnt">
                        <div className="">
                            <h1>Patient Care <i className='fa-solid fa-angle-right'></i></h1>
                            <p><Link>Providers</Link></p>
                            <p><Link>Visiting Hours and Direction</Link></p>
                            <p><Link>Online Forms</Link></p>
                            <p><Link>Request an Appointment</Link></p>
                            <p><Link>Testimonials and Reviews</Link></p>
                            <p><Link>Billing and Insurance</Link></p>
                        </div>
                        <div className="">
                            <h1>Patient Care <i className='fa-solid fa-angle-right'></i></h1>
                            <p><Link to={'/About'}>About</Link></p>
                            <p><Link>Quality & Safety</Link></p>
                            <p><Link>Careers</Link></p>
                            <p><Link>FAQ</Link></p>
                            <p><Link to={'/Contact'}>Contact & Location</Link></p>
                            <p><Link>Blog</Link></p>
                        </div>
                    </div>
                    <div className="main_foot_form_main">
                        <div className="foot_form">
                            <div className="">
                                <h1>Contacts <i className='fa-solid fa-angle-right'></i></h1>
                            </div>
                            <div className="foot_add">
                                <p>Address:</p>
                                <p className='bos'>Asian Vascular Hospital 8-2-676 2/B, Road No. 12, Sri Ram Nagar Colony, Banjara Hills,<br />
                                 Hyderabad, Telangana – 500034</p>
                            </div>
                            <div className="foot_add">
                                <p>Phone:</p>
                                <p><Link to={'tel:+91 733 732 1814'}>+91 733 732 1814</Link></p>
                            </div>
                            <div className="foot_add">
                                <p>Opening Hours:</p>
                                <p>Mo-Fri: 07:00-23:00h</p>
                            </div>
                            <div className="foot_brands d-flex gap-3 mt-2">
                                <i className='fa-brands fa-facebook' onClick={face_book}></i>
                                <i className='fa-brands fa-x-twitter' ></i>
                                <i className='fa-brands fa-youtube' onClick={youtube}></i>
                                <i className='fa-brands fa-instagram' onClick={insta}></i>
                                <i className='fa-brands fa-linkedin' onClick={linkedin}></i>
                            </div>
                            <Link className='btn dir_btn '>Directions</Link>
                        </div>
                    </div>
                </div>

                </div>
            </section>
            <div className="terms">
                    <div className="terms_left">
                        <small>© 2024
                        All rights reserved.</small>
                    </div>
                    <div className="terms_right">
                        <small>Sitemap
                        </small>
                        <small>Terms and Conditions</small>
                        <small><Link to={'/Privacy'} >Privacy Policy</Link></small>
                        <small>Manage Cookies</small>
                    </div>
                </div>

                <div className="nv_cntct_main" ref={formdis}>
           <div className="cnt_main  nv_cnct" data-aos="zoom-in" >
                            <div className="cnt_sub">
                                <div className="cnt_hd d-grid justify-content-center">
                                  <div className="text-end mb-1" onClick={forminvis}>
                                    <i className="fa-solid fa-x form_wrng" style={{marginTop:'-20px'}}></i>
                                  </div>

                                    <h1 style={{textAlign:'center'}}>Book Your Appointment</h1>

                                    <form className="cnt_cnt_form " onSubmit={handlesubmit}>
                                    <input type="text" placeholder='First Name' onChange={handledata} name='First_Name' value={data.First_Name} required/>
                                        <input type="text" placeholder='Family Name' onChange={handledata} name='Family_Name' value={data.Family_Name} required/>
                                        <input type="tel" placeholder='Phone Number' onChange={handledata} name='Phone_Number' value={data.Phone_Number} maxLength={10} pattern="^\d{10}$" required/>
                                        <input type="email" placeholder='Email' onChange={handledata} name='Email' value={data.Email} required/>
                                        <input type="text" placeholder='Subject' onChange={handledata} name='Subject' value={data.Subject} required/>
                                        <textarea name="Questions" id="" placeholder='Questions' onChange={handledata}  value={data.Questions} required></textarea>
                                        <button className='btn send_btn send_btn_2' type='submit'><i className='fa-solid fa-envelope' ></i> Book Appointment</button>
                                    </form>
                                </div>
                            </div>
              </div>
              </div>

              <div className="done_main" ref={donevis}>
              <div className="done">
              <i class="fa-solid fa-check"></i>
                  <span>Booked Successfully</span>
                <button className="btn send_btn" onClick={donehide}>Done</button>
              </div>
              </div>
        </div>
    );
};

export default Mainfooter;